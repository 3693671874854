<template>
  <sign-page
    v-loadingx="loading.load"
    title-text="报废申请记录"
    :request="request"
    :column-list="columnList"
    table-size="small"
    :table-actions="tableActions"
    @tableAction="tableAction"
    :title-menus="[]"
    :table-actions-fixed="true"
    :need-data-file="true">
    <fm-modal title="报废申请详细" v-model="modal" width="1000px" v-if="modal">
      <detail :statusMap="statusMap" :status-config="statusConfig" :id="chooseData.id"></detail>
    </fm-modal>
  </sign-page>
</template>

<script>
import {
  assetsScrapApplyRequest as request,
  statusManageRequest
} from '../../api'

import TextCell from '@/components/base/TextCell'

import detail from './apply/detail'
import { exportScrapPdf } from './lib'

export default {
  components: {
    detail
  },
  computed: {
    tableActions () {
      return [{
        key: 'detail',
        label: '明细'
      },
      {
        key: 'print',
        label: '打印'
      },
      {
        key: 'back',
        label: '撤销',
        show (data) {
          return data.actions.find(v => v.key === 'back_status_switch') && true
        }
      }]
    },
    columnList: {
      get () {
        let data = [{
          title: '编号',
          field: 'codeText',
          sort: true
        },
        {
          title: '类型',
          field: 'typeLabel',
          sort: true
        },
        {
          title: '报废资产',
          field: 'assetsText',
          sort: true,
          width: '300'
        },
        {
          title: '残余价值',
          field: 'jz',
          sort: true
        },
        {
          title: '申请人',
          field: 'userName',
          sort: true
        },
        {
          title: '申请科室',
          field: 'applyOrgName',
          sort: true
        },
        {
          title: '说明',
          field: 'remark',
          sort: true,
          render: (h, row) => {
            return h(TextCell, {
              props: {
                value: row.remark
              }
            })
          }
        },
        {
          title: '申请日期',
          field: 'createTime',
          dataType: Date,
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.createTime ? rowData.createTime.slice(0, 10) : '-')
          }
        },
        {
          title: '状态',
          field: 'statusText',
          sort: true
        }]
        return data
      }
    }
  },
  methods: {
    async tableAction (parm, vm) {
      this.chooseData = parm.data
      if (parm.action === 'detail') {
        this.modal = true
      } else if (parm.action === 'print') {
        this.loading.load = true
        let data = []
        if (this.$authFunsProxy.get) {
          data = await request.get({ id: parm.data.id, statusActionRecord: 1 })
        } else if (this.$authFunsProxy.getMyOrg) {
          data = await request.getMyOrg({ id: parm.data.id, statusActionRecord: 1 })
        }
        if (data.length) {
          await exportScrapPdf(data[0])
        }
        this.loading.load = false
      } else {
        let action = this.chooseData.actions.find(v => v.key === 'back_status_switch')
        if (action) {
          const result = await this.$dialog.confirm({title: '系统提示', content: '确定' + action.label + '该报废申请吗?'})
          if (result) {
            await request.switchStatus(this.chooseData.id, {
              statusActionKey: action.key,
              statusActionRecordId: action.statusActionRecordId,
            })
            vm.loadData()
          }
        }
      }
    },
    async loadConfig () {
      let c = await statusManageRequest.config({
        statusGroupKey: 'assetsScrapApply'
      })
      c.statusList.forEach(v => {
        this.statusMap[v.statusKey] = v.label
      })
      this.statusConfig = c.statusSwitchList.filter(v => v.isHost)
    },
    async loadData (parm) {
      let data = []
      if (this.$authFunsProxy.get) {
        data = await request.get(parm)
      } else if (this.$authFunsProxy.getMyOrg) {
        data = await request.getMyOrg()
      }
      data.forEach(v => {
        v.codeText = 'BFSQ' + v.id
        v.typeLabel = (this.typeList.find(v1 => v1.key === v.type) || {label: ''}).label
        v.assetsText = v.detailList.map(v1 => v1.assets.code + ' ' + v1.assets.name)
        v.assetsText = v.assetsText.join('，')
      })
      return data
    }
  },
  created () {
    this.loadConfig()
  },
  data () {
    return {
      request: {
        get: this.loadData
      },
      typeList: [{key: 'norm', label: '正常报废'}, {key: 'abnormal', label: '异常报废'}],
      statusMap: {},
      statusConfig: [],
      modal: false,
      chooseData: null,
      loading: {
        load: false
      }
    }
  }
}
</script>
