import sysEnv from '@/env/env'
import { fileRequest } from '@/api'
import { loadResourcesByCache } from '@/components/base/file/libv2'

const titleName = sysEnv.title || '未配置单位名称'

function exportPdf (html) {
  const id = new Date().getTime()+ '-' + parseInt(Math.random() * 100)
  const iframe = document.createElement('iframe')
  iframe.setAttribute('id', id)
  iframe.style.display = 'none'
  document.body.appendChild(iframe)
  const style = '<link rel="stylesheet" href="/static/document/table-print.css">'
  const print = '<script>document.addEventListener("DOMContentLoaded", function () {setTimeout(function () {window.print();setTimeout(function () {window.parent.document.getElementById("' + id + '").remove();}, 1000)}, 0);});</script>'
  iframe.srcdoc = '<!DOCTYPE html><head>' + style + '</head><body>' + html + '</body> ' + print + ' </html>'
}

export async function exportScrapPdf (data) {
  console.log(data)
  const base = data.statusActionRecord.map(({ id, statusKey, statusActionLabel, remark, userName, createTime, userSignFileId }) => {
    return { id, statusKey, statusActionLabel, remark, userName, createTime, userSignFileId }
  }).sort((a, b) => a.id - b.id)

  const signFileIds = Array.from(new Set([
    ...base.map(v => v.userSignFileId),
    data.userSignFileId,
    data.orgChargeUserSignFileId
  ])).filter(v => v)
  const signFileData = signFileIds.length ? await fileRequest.getFileData({
    ids: signFileIds.join(',')
  }) : []
  const signFiles = await Promise.all(signFileData.map(async file => {
    const blob = await loadResourcesByCache({ file })
    return { id: file.id, blob }
  }))

  let total = 0
  const items = (data.detailList || []).map((item, index) => {
    total += item.assets.jz ? Number(item.assets.jz) : 0
    return `
      <tr class="border">
        <td>${index + 1}</td>
        <td>${item.assets.name || ''}</td>
        <td>${item.assets.ggxh || ''}</td>
        <td>${item.assets.jz ? (item.assets.jz + '元') : ''}</td>
        <td>${item.assets.zmjz ? (item.assets.zmjz + '元') : ''}</td>
        <td>1</td>
        <td>${item.assets.getTime || ''}</td>
        <td>${item.assets.useOrgName || ''}</td>
        <td>${item.assets.useUserName || ''}</td>
      </tr>
    `
  }).join('')
  
  const records = [
    { label: '职能科室意见', key: 'wait_2' },
    // { label: '财务科意见', key: null },
    { label: '申请部门分管领导', key: 'wait_1' },
    { label: '资产管理分管领导', key: 'wait_3' },
    // { label: '财务分管领导', key: null },
    { label: '单位审批意见', key: 'wait_4' }
  ].map(item => {
    const res = base.filter(v => v.statusKey === item.key).pop()
    const sign = res ? signFiles.find(v => v.id === res.userSignFileId) : null
    console.log(item.key, res, base)
    return `
      <tr class="border">
        <td colspan="2">${item.label}</td>
        <td colspan="7">
          ${res ? (res.remark || res.statusActionLabel) : ''}<br />
          <div style="margin-top: 20px;justify-content: flex-end;display: flex;align-items: center;">
            签字：
            <span style="display: ${sign ? 'none' : 'inline-block'};min-width: 3cm;"></span>
            <img style="display: ${sign ? 'inline-block' : 'none'};height: 1cm;" src="${sign ? sign.blob : ''}" />
            <span style="display: inline-block;min-width: 3cm;">
              ${res ? res.createTime.substr(0, 10) : ''}
            </span>
          </div>
        </td>
      </tr>
    `
  }).join('')

  const applyUserSign = signFiles.find(v => v.id === data.userSignFileId)
  const applyUser = `
    <span style="display: ${applyUserSign ? 'none' : 'inline-block'};min-width: 3cm;"></span>
    <img style="display: ${applyUserSign ? 'inline-block' : 'none'};height: 1cm;" src="${applyUserSign ? applyUserSign.blob : ''}" />
  `

  const orgChargeUserSign = signFiles.find(v => v.id === data.orgChargeUserSignFileId)
  const orgChargeUser = `
    <span style="display: ${orgChargeUserSign ? 'none' : 'inline-block'};min-width: 3cm;"></span>
    <img style="display: ${orgChargeUserSign ? 'inline-block' : 'none'};height: 1cm;" src="${orgChargeUserSign ? orgChargeUserSign.blob : ''}" />
  `

  exportPdf(`
    <table class="single">
      <tr>
        <td colspan="9" style="font-weight: bolder;font-size: 18px;line-height: 55px;">${titleName}资产报废申请单</td>
      </tr>
      <tr style="border-bottom: 1px solid #000;">
        <td colspan="9" style="font-size: 15px;line-height: 35px;">
          申请部门：${data.applyOrgName || ''}
          申请人：${applyUser}
          申请日期：${data.createTime.substr(0, 10)}
        </td>
      </tr>
      <tr class="border">
        <td>序</td>
        <td>资产名称</td>
        <td>品牌及规格型号</td>
        <td>资产原值</td>
        <td>资产净值</td>
        <td>数量</td>
        <td>取得日期</td>
        <td>使用科室</td>
        <td>使用人</td>
      </tr>
      ${items}
      <tr class="border">
        <td colspan="9" style="text-align: center">合计：${total}元</td>
      </tr>
      <tr class="border">
        <td colspan="2">资产报废原因</td>
        <td colspan="2">${data.remark || ''}</td>
        <td colspan="2">科室负责人</td>
        <td colspan="3">${orgChargeUser}</td>
      </tr>
      ${records}
    </table>
  `)
}