<template>
  <div class="purchase-method-detail-base-info">
    <div class="base-info info-block">
      <div class="info-item">
        <div class="label">编码：</div>
        <div class="value">BFSQ{{data.id}}</div>
      </div>
      <div class="info-item">
        <div class="label">申请科室：</div>
        <div class="value">{{data.applyOrgName}}</div>
      </div>
      <div class="info-item">
        <div class="label">申请人：</div>
        <div class="value">{{data.userName}}</div>
      </div>
      <div class="info-item">
        <div class="label">说明：</div>
        <div class="value">{{data.remark}}</div>
      </div>
    </div>
    <div class="info-block" v-for="(item, index) in data.detailList" :key="item.id">
      <div class="info-item" style="width: 100%;" v-if="data.detailList.length > 1">
        <div class="label">报废资产{{index + 1}}：</div>
      </div>
      <div class="info-item">
        <div class="label">资产编码：</div>
        <div class="value">{{item.assets.code}}</div>
      </div>
      <div class="info-item">
        <div class="label">资产名称：</div>
        <div class="value">{{item.assets.name}}</div>
      </div>
      <div class="info-item">
        <div class="label">使用机构：</div>
        <div class="value">{{item.assets.useOrgName}}</div>
      </div>
      <div class="info-item">
        <div class="label">管理机构：</div>
        <div class="value">{{item.assets.manageOrgName}}</div>
      </div>
      <div class="info-item">
        <div class="label">价值：</div>
        <div class="value">{{item.assets.jz}}元</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    data: { type: Object, defualt: () => null }
  }
}
</script>

<style lang="less" scoped>
.purchase-method-detail-base-info {
  color: #515a6e;
  .info-block {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .detail-info {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #e8eaec;
  }
  .info-item {
    display: flex;
    margin-right: 50px;
    margin-bottom: 5px;
    .label {
      margin-right: 0px;
      white-space: nowrap;
    }
  }
}
</style>